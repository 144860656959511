const dbName = "ChatGPTMessages";
const storeName = "messages";
const indexedDB =
  window.indexedDB ||
  window.webkitIndexedDB ||
  window.mozIndexedDB ||
  window.msIndexedDB;

export const logUserPrompts = ({ conversation = [], category = "Generic" }) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "id", autoIncrement: true });
      }
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction([storeName], "readwrite");
      const store = transaction.objectStore(storeName);
      const message = {
        conversation,
        category,
        timestamp: new Date().toISOString(),
      };
      store.add(message);
      resolve("SUCCESS: Message added to IndexedDB");
    };

    request.onerror = (event) => {
      console.error("ERROR: Unable to open IndexedDB", event);
      reject(new Error("ERROR: Unable to open IndexedDB"));
    };
  });
};

export const getChatGptMessages = (category) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "id", autoIncrement: true });
      }
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);
      //   const index = store.index("category");

      const messages = [];
      const cursorRequest = store.openCursor();

      cursorRequest.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const thirtyDaysAgo = new Date();
          thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

          if (
            cursor.value.category === category &&
            new Date(cursor.value.timestamp) >= thirtyDaysAgo
          ) {
            messages.push(cursor.value);
          }
          cursor.continue();
        } else {
          messages.sort((a, b) => b.id - a.id);

          const today = [];
          const last7Days = [];
          const older = [];

          const now = new Date();
          const startOfToday = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
          );
          const sevenDaysAgo = new Date();
          sevenDaysAgo.setDate(now.getDate() - 7);

          messages.forEach((message) => {
            const messageDate = new Date(message.timestamp);
            if (messageDate >= startOfToday) {
              today.push(message);
            } else if (messageDate >= sevenDaysAgo) {
              last7Days.push(message);
            } else {
              older.push(message);
            }
          });

          resolve({ today, last7Days, older });
        }
      };

      cursorRequest.onerror = (event) => {
        // reject("ERROR: Unable to fetch messages", event);
        console.log("ERROR: Unable to fetch messages", event);
        reject(new Error("ERROR: Unable to fetch messages"));
      };
    };

    request.onerror = (event) => {
      console.log("ERROR: Unable to open IndexedDB", event);
      reject(new Error("ERROR: Unable to open IndexedDB"));
    };
  });
};
