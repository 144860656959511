var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chatgpt-component" }, [
    _c(
      "div",
      {
        staticClass: "chat-btn",
        attrs: { id: "chatgpt-button" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.openChatGpt()
          },
        },
      },
      [
        _c("img", {
          staticClass: "xr_bot",
          attrs: { src: "/images/ui/XrBot.svg", alt: "" },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "chatbot-container",
        class: {
          visible: _vm.isChatWindowActive,
          animated: _vm.isChatWindowActive,
        },
        attrs: { id: "chatgpt" },
      },
      [
        _c("div", { staticClass: "chat-window" }, [
          _c("div", { staticClass: "chat-header" }, [
            _vm.prevPrompts_today.length == 0 &&
            _vm.prevPrompts_last7days.length == 0 &&
            _vm.prevPrompts_last30days.length == 0
              ? _c("div", { staticClass: "title" })
              : _vm._e(),
            _vm._v(" "),
            _vm.prevPrompts_today.length > 0 ||
            _vm.prevPrompts_last7days.length > 0 ||
            _vm.prevPrompts_last30days.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "title",
                    on: {
                      click: function ($event) {
                        return _vm.openChatHistory()
                      },
                    },
                  },
                  [_c("img", { attrs: { src: "/images/ui/menu2.svg" } })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "close-button",
                on: {
                  click: function ($event) {
                    return _vm.closeChatGpt()
                  },
                },
              },
              [_c("img", { attrs: { src: "/images/header/exit.png" } })]
            ),
          ]),
          _vm._v(" "),
          _vm.messages.length > 0
            ? _c("div", { staticClass: "chat-body-container" }, [
                _vm.messages.length > 0
                  ? _c(
                      "div",
                      { staticClass: "chat-body" },
                      [
                        _vm._l(_vm.messages, function (message, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class: {
                                "user-message": message.role === "user",
                                "bot-message": message.role === "assistant",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(message.content) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.isResponseLoading
                          ? _c("div", { staticClass: "bot-message" }, [
                              _c("div", [_vm._v("Loading...")]),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.messages.length === 0
            ? _c("div", { staticClass: "chat-body-container" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "chat-topic-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.chatCategoryDetails.title) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "chat-topic-description" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.chatCategoryDetails.description) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "chat-prompt-container" },
                  _vm._l(_vm.promptSuggestions, function (suggestion, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "chat-prompt-item",
                        on: {
                          click: function ($event) {
                            return _vm.askChatGPT(suggestion.text)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: { src: `/images/ui/${suggestion.icon}` },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "prompt" }, [
                          _vm._v(_vm._s(suggestion.text)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "chat-footer" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newMessage,
                  expression: "newMessage",
                },
              ],
              staticClass: "message-input",
              attrs: {
                type: "text",
                placeholder: `Message ${_vm.chatCategoryDetails.title}...`,
              },
              domProps: { value: _vm.newMessage },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.sendMessage.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.newMessage = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "send-button",
                attrs: { id: "send-btn" },
                on: {
                  click: function ($event) {
                    return _vm.sendMessage()
                  },
                },
              },
              [_c("span", { staticClass: "arrow-up" }, [_vm._v("↑")])]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "chatbot-history-container",
        class: {
          visible: _vm.isChatHistoryActive,
          animated: _vm.isChatHistoryActive,
        },
        attrs: { id: "chatgpthistory" },
      },
      [
        _c("div", { staticClass: "chat-window" }, [
          _c("div", { staticClass: "chat-header" }, [
            _c("div", {
              staticClass: "title",
              on: {
                click: function ($event) {
                  return _vm.openChatHistory()
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "logo" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "close-button",
                on: {
                  click: function ($event) {
                    return _vm.closeChatHistory()
                  },
                },
              },
              [_c("img", { attrs: { src: "/images/header/backico.svg" } })]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "chat-body-container" }, [
            _vm.prevPrompts_today.length > 0
              ? _c(
                  "div",
                  { staticClass: "chat-body" },
                  [
                    _vm._v("\n          Today\n          "),
                    _vm._l(_vm.prevPrompts_today, function (prompt, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "prompt-item",
                          on: {
                            click: function ($event) {
                              return _vm.loadPreviousConversation(prompt.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                prompt.conversation[0].role === "user"
                                  ? prompt.conversation[0].content
                                  : prompt.conversation[1].content
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.prevPrompts_last7days.length > 0
              ? _c(
                  "div",
                  { staticClass: "chat-body" },
                  [
                    _vm._v("\n          Last 7 Days\n          "),
                    _vm._l(_vm.prevPrompts_last7days, function (prompt, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "prompt-item",
                          on: {
                            click: function ($event) {
                              return _vm.loadPreviousConversation(prompt.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                prompt.conversation[0].role === "user"
                                  ? prompt.conversation[0].content
                                  : prompt.conversation[1].content
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.prevPrompts_last30days.length > 0
              ? _c(
                  "div",
                  { staticClass: "chat-body" },
                  [
                    _vm._v("\n          Last 30 Days\n          "),
                    _vm._l(
                      _vm.prevPrompts_last30days,
                      function (prompt, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "prompt-item",
                            on: {
                              click: function ($event) {
                                return _vm.loadPreviousConversation(prompt.id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  prompt.conversation[0].role === "user"
                                    ? prompt.conversation[0].content
                                    : prompt.conversation[1].content
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: "/images/header/logo.svg" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "chat-topic-title-icon" }, [
      _c("img", { attrs: { src: "/images/ui/bot.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }